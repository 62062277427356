import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

//パラメータは以下参照
//https://swiperjs.com/swiper-api#parameters

export function slider(sliderEl) {
  if ($(sliderEl).length) {
    const swiper = new Swiper(sliderEl, {
      direction: "horizontal",
      loop: false,
      autoHeight: false,
      centeredSlides: false,
      effect: "slide",
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 5000,
      },
    });
  }
}
