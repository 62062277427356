import { scrollAnime } from "./components/scrollAnime";
import { humbergerMenuButton } from "./components/humbergerMenuButton";
import { accordion } from "./components/accordion";
import { tab } from "./components/tab";
import { slider } from "./components/slider";
import { inputError } from "./components/inputError";
import { megaMenu } from "./components/megaMenu";
import { pageTop } from "./components/pageTop";
import ScrollHint from 'scroll-hint';


$(document).ready(function () {

  (() => {
    /*
    アニメーション用関数
    */
    //アニメーション用クラスを定義
    const animeEl = ".u-anime";
    //スクロール時に付与されるクラス定義
    const addAnimeClass = "fadeIn";
    scrollAnime(animeEl, addAnimeClass);

  })();

  (() => {
    /*
    ハンバーガーメニュー用関数
    */
    //ハンバーガーメニューボタンクラス定義
    const buttonEl = "#humberge-button";
    //ハンバーガーメニュークラス定義
    const menuEl = "#humberge-menu";
    humbergerMenuButton(buttonEl, menuEl);
  })();

  (() => {
    /*
    スライダー用関数
    */
    const sliderEl = ".swiper";
    slider(sliderEl);
  })();

  (() => {
    /*
    アコーディオン用関数
    */
    //アコーディオンラッパークラス定義
    const accordionEl = ".accordion";
    //アコーディオンボタンクラス定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテムクラス定義
    const accordionItemEl = ".accordionItem";
    accordion(accordionEl, accordionButtonEl, accordionItemEl);
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);
  })();

  (() => {
    /*
    メガメニュー用関数
    */
    //メガメニューラッパークラス定義
    const megaMenuEl = "#megaMenu";
    //メガメニューナビアイテムクラス定義
    const megaMenuItemEl = ".megaMenuItem";
    //メガメニューコンテンツクラス定義
    const megaMenuContents = ".megaMenuContents";
    megaMenu(megaMenuEl, megaMenuItemEl, megaMenuContents);
  })();

  (() => {
    /*
    フォームバリデーション用関数
    */
    //フォームラッパークラス定義
    const formEl = ".p-form";
    //エラー表示用クラス定義
    const addErrorClass = "c-error";
    //WPなどでフォームから出力されるエラークラスを定義
    const errorEl = "error";
    inputError(formEl, addErrorClass, errorEl);
  })();

  (() => {
    /*
    ページトップボタン用関数
    */
    //ボタンクラス定義
    const pageTopButtonEl = "#pageTop";
    //初期状態を定義
    const hide = true;
    pageTop(pageTopButtonEl, hide);
  })();
  
});

//ヘッダーのサービスソリューションのトグルメニュー

$("#servicebtn").on("click", function () {
  if ($(this).children("a").hasClass("current")) {
    $(this).children("a").removeClass("current");
  } else {
    $(this).children("a").addClass("current");
  }

  $(this).children(".p-header__menu__service__child").slideToggle();
});

$(function () {
  $('.p-header__menu--pc__bottommenu > li > a').each(function () {
    var $href = $(this).attr('href');
    $href = $href.replaceAll("../", "");
    var urlhead = $href.slice(0, 7);

    if (location.href.match(urlhead)) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });
});

$(function () {
  $('.p-header__menu--pc__topmenu li a').each(function () {
    var $href = $(this).attr('href');
    $href = $href.replaceAll("../", "");

    if (location.href.match($href)) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });
}); 

//ページ内リンク スムーススクロール

$(function () {
  $('a[href^="#"]').on('click', function () {
    var adjust = -100;
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
});

//ページ遷移 スムーススクロール

$(function () {
  var hash = location.hash;

  if (hash) {
    var adjust = -100;
    $("html, body").stop().scrollTop(0);
    setTimeout(function () {
      var target = $(hash),
          position = target.offset().top + adjust;
      $("html, body").animate({
        scrollTop: position
      }, 500, "swing");
    });
  }
});

//faq系のトグルメニュー

$(function () {
  var toggles = $('.c-toggle--faq__q');
  toggles.on("click", function (event) {
    event.preventDefault();
    $(this).children(".c-toggle--faq__q__icon").toggleClass("on");
    $(this).next(".c-toggle--faq__a").slideToggle();
  });
});

//サイドメニュー　カレント表示

$(function () {
  $('.c-sidemenu__list li div a').each(function () {
    var $href = $(this).attr('href');
    $href = $href.replaceAll("../", "");

    if (location.href.match($href)) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });
});

$(function () {
new ScrollHint('.js-scrollable', {
  i18n: {
    scrollable: 'スクロールできます'
  }
});
});

$(function () {
  const mediaQuery = window.matchMedia('(min-width: 768px)');
  const fixmenu = $(".c-bnr__fixmenu");
  if (window.location.href.indexOf('faq') !== -1) { 
    // この部分で「特定のページ」を、実際に非表示したいページのURLの一部として置き換えてください。
    
    // 要素を非表示にする
    fixmenu.hide();
    $(".p-footer").addClass("faq");
    return false;
}
  function checkWindow(windowSize) {
    if (windowSize.matches) {
      fixmenu.show();
      $(window).on("scroll" , function () {
        const $scrollTop = $(this).scrollTop();
        if ($scrollTop) {
          if ($scrollTop > 500) {
            fixmenu.addClass('active');
          } else {
            fixmenu.removeClass('active');
          }
        }
      });
    } else {
      fixmenu.hide();
      $(window).on("scroll" , function () {
        const $scrollTop = $(this).scrollTop();
        if ($scrollTop) {
          if ($scrollTop > 500) {
            fixmenu.slideDown(300);
          } else {
            fixmenu.slideUp(300);
          }
        }
      });
    }
  }
  
  checkWindow(mediaQuery);
  
  mediaQuery.addEventListener('change', checkWindow);
});

$(function () {
  var url = location.href;
  let anchrnum = url.slice(-2);
  let targetnum = '#problems' + anchrnum;
  if (url.match(/#problems/)) {
    $(targetnum).find(".c-toggle--faq__q__icon").toggleClass("on");
    $(targetnum).find(".c-toggle--faq__a").slideToggle();
  }
});


